import React from "react";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {Link} from 'gatsby-plugin-react-i18next';


const IndexPage = (props) => {
  const {t} = useTranslation();
  return (
      <Layout>
        <Seo
            title={t('digilock_upgrader_program_terms_conditions')}
            description={t('des_digilock_upgrader_program_terms_conditions')}
        />
        <div className="support upgrade-program-terms">

          <section>
            <div className="container">
              <h1>
                Upgrader Program Terms & Conditions
              </h1>
              <p>
                Security People, Inc. dba Digilock, including its Affiliates (“Digilock”), offers this Upgrade Program (the “Program”). By participating in the Program, you are agreeing to these Upgrade Program Terms and Conditions (“Upgrade Terms”).
              </p>
              <ol>
                <li>
                  <strong>Program</strong>. The Program offers the opportunity for interested customers (“You”) to purchase eligible new Digilock-brand electronic locking devices (“New Locks”) at a discounted price or receive a refund in connection with Your return to Digilock of certain other Digilock-brand electronic locking devices that You previously purchased, installed, and used (“Used Locks”). All purchases under the Program are subject to Digilock’s Standard Terms and Conditions, available at <Link to={'/terms-and-conditions/'}>www.digilock.com/terms-and-conditions/</Link> (“Standard Terms”).
                </li>
                <li>
                  <strong>Eligibility</strong>.
                  <ol type={'a'}>
                    <li>To qualify for the Program, You must indicate to Digilock that you are seeking to participate in the Program and receive a Return Merchandise Authorization for your Used Locks <strong>prior to purchasing New Locks</strong>.  You must purchase New Locks of a technological generation later in time than the Used Locks. Products and services that are not electronic locking devices offered and sold by Digilock are outside the scope of the Program. The number of New Locks eligible for the discount is limited to the number of conforming Used Locks returned to Digilock.</li>
                    <li>
                      All Used Locks must be complete, with all original parts (or Digilock-approved replacement parts) fully incorporated into the Used Locks. The Used Locks do not need to be in working order and may exhibit normal wear and tear. It is Your sole responsibility to download any data stored on any Used Lock prior to return. Data recovery is not part of the Program and Digilock shall have no liability for any lost data.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Discount</strong>. Each model and generation of New Lock shall have a discount applied to it, or a refund associated with it, relative to its List Price, as determined and quoted solely by Digilock, on a per project basis under the Program.  If You return the Used Locks prior to shipment of the New Locks, a discount will be applied to purchase of the New Locks.  If you return the Used Locks after our shipment of the New Locks, a refund will be provided to You. Digilock reserves the right to revise or eliminate any discount for any New Lock at any time subject to any order already accepted and entered by Digilock.
                </li>
                <li>
                  <strong>Return</strong>. If Your purchase of New Locks qualifies under the Program, Digilock will issue You a Return Merchandise Authorization for the Used Locks.  You must ship the Used Locks to an address supplied by Digilock within 30 days of receipt of the New Locks.  The discount or refund shall be applied or issued upon receipt by Digilock at its facility of the Used Locks and its qualification thereof.
                </li>
                <li>
                  <strong>Shipping</strong>.  All fees, costs, insurance, and risks associated with shipping Used Locks to Digilock shall be borne by You. Digilock shall not take possession of the Used Locks until they reach Digilock’s facility.
                </li>
                <li>
                  <strong>Termination</strong>. Digilock may revise or terminate the Program at any time, with or without cause, and with or without notification to any customer. Any order entered by Digilock pursuant to this Program prior to termination will be honored.
                </li>
                <li>
                  <strong>General</strong>.  These Upgrade Terms are in addition to and expressly incorporate the Standard Terms. If any conflict arises between the two, the Standard Terms take precedence. This Program is available on a world-wide basis to any qualified customer.
                </li>
              </ol>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allSupportGeneralFaqXlsxLockFunctionality {
      edges {
        node {
          id
            title
            content
        }
      }
    }
    allSupportGeneralFaqXlsxSetupProgramming {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  
  allSupportGeneralFaqXlsxTroubleShooting {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  
  allSupportGeneralFaqXlsxOrdersSupport {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  }
`;
